import { ACTIONS } from './constants';
import _ from 'lodash';
import { Data, MatrixLoadState } from './state';
import { ProcessedMatrixData } from './@types/types';

export const loadData = (payload: Data) => {
  return {
    type: ACTIONS.LOAD_DATA,
    payload,
  };
};
export const setMatrixLoadState = (payload: MatrixLoadState) => {
  return {
    type: ACTIONS.SET_MATRIX_LOAD_STATE,
    payload,
  };
};

export const setSelectedDistricts = (payload: string[]) => {
  return {
    type: ACTIONS.SET_SELECTED_DISTRICTS,
    payload,
  };
};

export const setSelectedLanguage = (payload: string) => {
  return {
    type: ACTIONS.SET_SELECTED_LANGUAGE,
    payload,
  };
};

export const setSelectedOpeningTimeFrom = (payload: string) => {
  return {
    type: ACTIONS.SET_SELECTED_OPENING_TIME_FROM,
    payload,
  };
};

export const setSelectedOpeningTimeTo = (payload: string) => {
  return {
    type: ACTIONS.SET_SELECTED_OPENING_TIME_TO,
    payload,
  };
};

export const setSelectedTravelTime = (payload: string) => {
  return {
    type: ACTIONS.SET_SELECTED_TRAVEL_TIME,
    payload,
  };
};

export const setSelectedTravelDistance = (payload: string) => {
  return {
    type: ACTIONS.SET_SELECTED_TRAVEL_DISTANCE,
    payload,
  };
};

export const toggleKitaSelection = (payload: string) => {
  return {
    type: ACTIONS.TOGGLE_KITA_SELECTION,
    payload,
  };
};

export const selectAllFilteredKitas = (payload: string[]) => {
  return {
    type: ACTIONS.SELECT_ALL_FILTERED_KITAS,
    payload,
  };
};

export const deselectAllSelectedKitas = (payload: string[]) => {
  return {
    type: ACTIONS.DESELECT_ALL_SELECTED_KITAS,
    payload,
  };
};

export const setMapMenuVisibility = (payload = true) => {
  return {
    type: ACTIONS.SET_MAP_MENU_VISIBILITY,
    payload,
  };
};

export const locationChange = (payload: string) => {
  return {
    type: ACTIONS.LOCATION_CHANGE,
    payload,
  };
};

export const loadMatrix = (payload: ProcessedMatrixData) => {
  return {
    type: ACTIONS.LOAD_MATRIX,
    payload,
  };
};
