import { envs } from '../constants';
import { SelectOptions, OpeningTimes, OpeningTime } from '../@types/types';
import { navigate } from 'gatsby';

let env: null | string = null;

const getEnv = () => {
    if (env === null) {
        env = envs.PRODUCTION;
        if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
            env = envs.DEVELOPMENT;
        }
    }
    return env;
};

/**
 * return false if running in development environment
 */
export const inDevEnv = () => getEnv() === envs.DEVELOPMENT;

/**
 * return true if running in production environment
 */
export const inProdEnv = () => getEnv() === envs.PRODUCTION;

/**
 * pick the option whose value equals the given value
 */
export const findOption = (options: SelectOptions, value: string) => {
    return options.find(option => option.value === value);
};

/**
 * labels items
 */
export const labelItems = (items: string[]) => {
    return items.map(item => ({ label: item, value: item }));
};

/**
 * format opening times
 */
export const formatOpeningTime = (time: number) => {
    return {
        label: time.toFixed(2).replace('.', ':'),
        value: time.toString()
    };
};

/**
 * format travel distance
 */
export const formatTravelDistance = (distance: number) => {
    let label = '--';
    if (distance !== -1)
        label = distance >= 1000 ? (distance / 1000).toFixed(1) + 'km' : distance + 'm';
    return {
        label,
        value: distance.toString()
    };
};

/**
 * format travel time
 */
export const formatTravelTime = (time: number) => {
    let label = '--';
    if (time !== -1) {
        const hours = Math.floor(time / 3600);
        const mins = Math.floor((time - hours * 3600) / 60);
        label = `${hours}hrs, ${mins}min`;
    }
    return {
        label,
        value: time.toString()
    };
};

/**
 * sets selected on each item in the items array whose value equals the second argument
 */
export const setSelected = <T>(items: Array<{ value: T, selected: boolean }>, selected: T) => {
    return items.map(item => {
        item.selected = item.value === selected;
        return item;
    });
};

/**
 * sets selected on each item in the items array whose values exists in the second array argument
 */
export const setArraySelected = <T>(items: Array<{ value: T, selected: boolean }>, selected: T[]) => {
    return items.map(item => {
        item.selected = selected.includes(item.value);
        return item;
    });
};

/**
 * credits to the following
 * dreamyguy https://gist.github.com/dreamyguy/6b4ab77d2f118adb8a63c4a03fba349d
 * js-download' https://github.com/kennethjiang/js-file-download
 * 'Anders Paulsen' https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
 */
export const sendDownload = (data: Buffer | string, filename: string, mime?: string) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const blob = new Blob([data], { type: mime || 'application/octet-stream' });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE doesn't allow using a blob object directly as link href.
        // Workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
        return;
    }

    // Other browsers
    // Create a link pointing to the ObjectURL containing the blob
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);

    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL);
    }, 100);
};

/**
 * navigates to a given kita
 */
export const gotoKita = (kitaId: string, kitaSlug: string) => {
    navigate(`/search/kita/${kitaId}/${kitaSlug}`);
};

/**
 * leave kita and returns to search page
 */
export const leaveKita = () => {
    navigate(`/search`);
};

/**
 * capitalize text
 */
export const capitalize = (text: string) => {
    return text.length > 0? text.charAt(0).toUpperCase() + text.substring(1) : text;
};

/**
 * pair opening hours
 */
export const pairOpeningHours = (openingHours: OpeningTimes) => {

    const pairs: Array<[string[], [number, number]]> = [];

    const days = Object.keys(openingHours);
    const hours: OpeningTime[] = days.map(day => openingHours[day]);

    let pair: [string[], [number, number]] = [[], [0, 0]];

    const length = days.length;
    let i = -1;
    while (++i < length) {

        const currentHour = hours[i];
        const currentDay = days[i];
        const isLast = i === length - 1;

        if (i === 0) {
            pair = [[currentDay], [currentHour.from, currentHour.to]];
        }

        const [, [hourFrom, hourTo]] = pair;
        const isSame = currentHour.from === hourFrom && currentHour.to === hourTo;

        // if they are not the same, push in the last check, and start a fresh pair
        if (!isSame) {
            if (i > 0) {
                const endDay = days[i - 1];
                pair[0].push(endDay);
            }
            pairs.push(pair);
            pair = [[currentDay], [currentHour.from, currentHour.to]];
        }

        // if this is the last, finish up
        if (isLast) {
            if (isSame && i !== 0) {
                pair[0].push(currentDay);
            }
            pairs.push(pair);
        }
    }

    return pairs.map(([dayPair, hourPair]) => {
        return `${dayPair.join(' - ')}: ${hourPair.map(hour => formatOpeningTime(hour).label).join(' - ')}`;
    });
};