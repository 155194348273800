import { OpeningTimes, GeoDistricts, ChildImageSharpFluid } from './@types/types';
import {
    DEFAULT_LANGUAGE, DEFAULT_OPENING_TIME_FROM,
    DEFAULT_OPENING_TIME_TO,
    DEFAULT_TRAVEL_TIME,
    DEFAULT_TRAVEL_DISTANCE
} from './constants';

export interface UserChoice {

    selectedDistricts: string[];

    selectedLanguage: string;

    selectedOpeningTimeFrom: string;

    selectedOpeningTimeTo: string;

    selectedTravelTime: string;

    selectedTravelDistance: string;
}

export interface Matrix {
    distance: number;
    travelTime: number;
}

/**
 * kita interface
 */
export interface Kita {

    id: string;

    name: string;

    slug: string;

    email: string;

    phone: string;

    website: string;

    location: [number, number];

    address: {
        street: string,
        district: string
    };

    openingTimes: OpeningTimes;

    pedagogicalPoints: string;

    minOpeningTimeFrom: null | number;

    maxOpeningTimeTo: null | number;

    languages: string[];

    errorFlags: {
        openingTimesNotKnown?: boolean;
        languagesNotKnown?: boolean
    };

    selected: boolean;

    matrix: Matrix;
}

export interface Data {

    kitas: Kita[];

    kitaImages: ChildImageSharpFluid[];

    defaultKitaImage: ChildImageSharpFluid | null;

    geoDistricts: GeoDistricts;

    districts: string[];

    languages: string[];

    openingTimesFrom: number[];

    openingTimesTo: number[];

    index: {
        // index built based on kita ids mapping a kita id to the kita location
        kitaIds: {
            [propName: string]: number
        },

        // index built based on kita ids mapping a kita id to the kita image location
        kitaImageIds: {
            [propName: string]: number
        }
    };
}

export interface ComponentVisibilities {
    mapMenu: boolean;
}

export type MatrixLoadState = 'default' | 'requesting' | 'denied' |
    'loading' | 'loaded' | 'loadError';

export interface MatrixBounds {
    loadState: MatrixLoadState;
    minDistance: number;
    maxDistance: number;
    minTravelTime: number;
    maxTravelTime: number;
}

/**
 * appstate interface
 */
export interface AppState {

    data: Data;

    userChoice: UserChoice;

    componentVisibilities: ComponentVisibilities;

    matrixBounds: MatrixBounds;
}

/**
 * export the state
 */
const state: AppState = {
    data: {

        kitas: [],

        defaultKitaImage: null,

        kitaImages: [],

        defaultKitaImage: null,

        geoDistricts: {
            features: []
        },

        districts: [],

        languages: [],

        openingTimesFrom: [],

        openingTimesTo: [],

        index: {
            kitaIds: {},

            kitaImageIds: {}
        }
    },

    userChoice: {

        selectedDistricts: [],

        selectedLanguage: DEFAULT_LANGUAGE.value,

        selectedOpeningTimeFrom: DEFAULT_OPENING_TIME_FROM.value,

        selectedOpeningTimeTo: DEFAULT_OPENING_TIME_TO.value,

        selectedTravelTime: DEFAULT_TRAVEL_TIME.value,

        selectedTravelDistance: DEFAULT_TRAVEL_DISTANCE.value
    },

    componentVisibilities: {
        mapMenu: true
    },

    matrixBounds: {
        loadState: 'default',
        minDistance: 0,
        maxDistance: 0,
        minTravelTime: 0,
        maxTravelTime: 0
    }
};
export default state;