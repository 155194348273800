import { trackPageView } from '@redux-beacon/google-analytics';
import { ACTIONS } from './constants';
import { inDevEnv } from './lib/Utils';

export const setupGA = (name, key, debugName) => {
  if (typeof window !== 'undefined' && typeof window[name] === 'undefined') {
    window[key] = name;
    window[name] = (...args) => {
      window[name].q = window[name].q || [];
      window[name].q.push(args);
    };

    window[name]('create', 'UA-86953911-3', 'auto');

    if (inDevEnv()) {
      window[debugName] = { trace: true };
      window[name]('set', 'sendHitTask', null);
    }
  }
};

export default {
  [ACTIONS.LOCATION_CHANGE]: trackPageView(action => {
    return {
      page: action.payload,
      title: document.title
    };
  })
};
