import { ACTIONS } from './constants';
import state, { UserChoice, Data, ComponentVisibilities, MatrixBounds, MatrixLoadState } from './state';
import { ProcessedMatrixData } from './@types/types';

export interface Action<T> {
    type: string;
    payload: T;
}

/**
 * reduce data state component
 */
export const data = (prev = state.data, action: Action<Data> | Action<string> | Action<string[]> | Action<ProcessedMatrixData>): Data => {
    switch (action.type) {

        case ACTIONS.LOAD_DATA:
            return (action as Action<Data>).payload;

        case ACTIONS.LOAD_MATRIX:
            return {
                ...prev,
                kitas: prev.kitas.map((kita, index) => {
                    return {
                        ...kita,
                        matrix: {
                            ...(action as Action<ProcessedMatrixData>).payload.matrix[index]
                        }
                    };
                })
            };

        case ACTIONS.TOGGLE_KITA_SELECTION:
            return {
                ...prev,
                kitas: prev.kitas.map(kita => {
                    if (kita.id === (action as Action<string>).payload) {
                        return {
                            ...kita,
                            selected: !kita.selected
                        };
                    }
                    else {
                        return kita;
                    }
                })
            };

        case ACTIONS.SELECT_ALL_FILTERED_KITAS:
            return {
                ...prev,
                kitas: prev.kitas.map(kita => {
                    if ((action as Action<string[]>).payload.includes(kita.id)) {
                        return {
                            ...kita,
                            selected: true
                        };
                    }
                    else {
                        return kita;
                    }
                })
            };

        case ACTIONS.DESELECT_ALL_SELECTED_KITAS:
            return {
                ...prev,
                kitas: prev.kitas.map(kita => {
                    if ((action as Action<string[]>).payload.includes(kita.id)) {
                        return {
                            ...kita,
                            selected: false
                        };
                    }
                    else {
                        return kita;
                    }
                })
            };

        default:
            return prev;
    }
};

/**
 * reduce userChoice state component
 */
export const userChoice = (prev = state.userChoice, action: Action<string> | Action<string[]>): UserChoice => {
    switch (action.type) {

        case ACTIONS.SET_SELECTED_DISTRICTS:
            return {
                ...prev,
                selectedDistricts: (action as Action<string[]>).payload
            };

        case ACTIONS.SET_SELECTED_LANGUAGE:
            return {
                ...prev,
                selectedLanguage: (action as Action<string>).payload
            };

        case ACTIONS.SET_SELECTED_OPENING_TIME_FROM:
            return {
                ...prev,
                selectedOpeningTimeFrom: (action as Action<string>).payload
            };

        case ACTIONS.SET_SELECTED_OPENING_TIME_TO:
            return {
                ...prev,
                selectedOpeningTimeTo: (action as Action<string>).payload
            };

        case ACTIONS.SET_SELECTED_TRAVEL_TIME:
            return {
                ...prev,
                selectedTravelTime: (action as Action<string>).payload
            };

        case ACTIONS.SET_SELECTED_TRAVEL_DISTANCE:
            return {
                ...prev,
                selectedTravelDistance: (action as Action<string>).payload
            };

        default:
            return prev;
    }
};

/**
 * reduce component visibilities
 */
export const componentVisibilities = (prev = state.componentVisibilities,
    action: Action<boolean>): ComponentVisibilities => {

    switch (action.type) {

        case ACTIONS.SET_MAP_MENU_VISIBILITY:
            return {
                ...prev,
                mapMenu: action.payload
            };

        default:
            return prev;
    }
};

/**
 * reduce matrixBounds state component
 */
export const matrixBounds = (prev = state.matrixBounds, action: Action<ProcessedMatrixData> | Action<MatrixLoadState>): MatrixBounds => {
    switch (action.type) {

        case ACTIONS.SET_MATRIX_LOAD_STATE:
            return {
                ...prev,
                loadState: (action as Action<MatrixLoadState>).payload
            };

        case ACTIONS.LOAD_MATRIX:
            return {
                ...prev,
                loadState: 'loaded',
                minDistance: (action as Action<ProcessedMatrixData>).payload.minDistance,
                maxDistance: (action as Action<ProcessedMatrixData>).payload.maxDistance,
                minTravelTime: (action as Action<ProcessedMatrixData>).payload.minTravelTime,
                maxTravelTime: (action as Action<ProcessedMatrixData>).payload.maxTravelTime
            };

        default:
            return prev;
    }
};