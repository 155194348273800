import { combineReducers, createStore, applyMiddleware } from 'redux';
import { createMiddleware } from 'redux-beacon';
import GoogleAnalytics from '@redux-beacon/google-analytics';

import * as reducers from './reducers';
import gaEventMap, { setupGA } from './ga-events';

setupGA('ga', 'GoogleAnalyticsObject', 'ga_debug');

const middleware = createMiddleware(gaEventMap, GoogleAnalytics());

export default createStore(
    combineReducers(reducers),
    applyMiddleware(middleware)
);