export const envs = {
  PRODUCTION: 'prod',
  DEVELOPMENT: 'dev'
};

export const DEFAULT_LANGUAGE = {
  label: 'All',
  value: ''
};

export const DEFAULT_OPENING_TIME_FROM = {
  label: 'From',
  value: ''
};

export const DEFAULT_OPENING_TIME_TO = {
  label: 'To',
  value: ''
};

export const DEFAULT_TRAVEL_TIME = {
  label: 'Any',
  value: ''
};

export const DEFAULT_TRAVEL_DISTANCE = {
  label: 'Any',
  value: ''
};

export const BERLIN_LAT_LNG_BOUND = [[52.520008, 13.404954]];

export const INITIAL_MAP_ZOOM = 11.15;

export const SIDE_MENU_TRANSITION_DURATION = 250;

export const ACTIONS = {
  LOAD_DATA: 'load/data',

  LOAD_MATRIX: 'load/matrix',

  SET_MATRIX_LOAD_STATE: 'set/matrix-load-state',

  SET_SELECTED_DISTRICTS: 'set/selected-districts',

  SET_SELECTED_LANGUAGE: 'set/selected-language',

  SET_SELECTED_OPENING_TIME_FROM: 'set/selected-opening-time-from',

  SET_SELECTED_OPENING_TIME_TO: 'set/selected-opening-time-to',

  SET_SELECTED_TRAVEL_TIME: 'set/selected-travel-time',

  SET_SELECTED_TRAVEL_DISTANCE: 'set/selected-travel-distance',

  TOGGLE_KITA_SELECTION: 'toggle-kita-selection',

  SELECT_ALL_FILTERED_KITAS: 'select-all-filtered-kitas',

  DESELECT_ALL_SELECTED_KITAS: 'deselect-all-selected-kitas',

  SET_MAP_MENU_VISIBILITY: 'toggle-map-menu-visibility',

  LOCATION_CHANGE: 'location/change'
};

export const REQUEST_LOCATION_AFTER = 3000;

export const MATRIX_ENDPOINT =
  'https://matrix.route.api.here.com/routing/7.2/calculatematrix.json';

export const MATRIX_ENDPOINT_COMMON_PARAMS = {
  mode: 'fastest;car;traffic:disabled;',
  summaryattributes: 'traveltime,distance',
  app_id: 'msQ5e6YTZZgIesMsPDYJ',
  app_code: 'EAPxlxBUdfHmzJTxSfKKdQ'
};

export const DOUBLE_CLICK_THRESHOLD = 200;

export const ROUTES = {
  kitaDetails: '/search/kita/:kitaId/:kitaSlug'
};

export const MAX_CLUSTERING_ZOOM = 16;

// 992px - (16px *2) = 960px
export const CONTAINER_MAX_WIDTH = '1264px';

export const CONTENT_MAX_WIDTH = '960px';

export const MAKERSDEN_WEBSITE = 'https://makersden.io';
